@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

@import '@financial-times/community-event-teaser/main';

.aside--community {
	margin: 0 0 oSpacingByName('s8');

	@include oGridRespondTo(M) {
		margin: oSpacingByName('m16') 0;
	}

	@include oGridRespondTo(XL) {
		padding-left: 0;
	}

	.aside--community__subsection-title {
		@include oTypographySans(2);
	}

	.aside--community__subsection-strapline {
		@include oTypographySans(0);
	}

	.o-teaser-community__heading {

		a {
			color: black;
			text-decoration-line: none;
		}

		&:after {
			content: "";
			display: block;
			width: 20%;
			margin: 12px 0 auto;
			border-bottom: 2px solid oColorsByName('black');
		}
	}

	.o-teaser-community__header {

		a {
			color: oColorsByName('black');
			text-decoration: none;
		}
	}

	.event-teaser {
		margin-bottom: oSpacingByName('s4');
	}

	.o-teaser-community__button-wrapper {
		display: flex;
		justify-content: center;
	}

	.aside--community__community-button {
		@include oButtonsContent($opts: ('type': 'secondary'));
		@include oTypographySans($scale: 0, $weight: 'semibold');
		margin: oSpacingByName('s3') 0;
		padding: oSpacingByName('s3') oSpacingByName('s6');
	}

	.event-teaser .event-teaser__title:hover {
		text-decoration: underline;
		color: oColorsByName('teal-30');
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:158");