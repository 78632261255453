@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-typography/main';

.o-teaser-collection {
	clear: left;

	.section-meta {
		display: none;
	}

	.stream-item .o-teaser--small {
		min-height: 70px;
	}
}

.industry-recommendations {
	@include oGridRespondTo($from: XL) {
		padding-left: 0;
	}

	.o-teaser-collection__heading {
		padding-bottom: oSpacingByName('s4');
	}
}

.industry-recommendations + .o-ads {
	padding-top: 25px;
}

.o-labels {
	@include oTypographySans($scale: -2, $weight: 'semibold');
}

.o-teaser-collection--horizontal {
	&.o-teaser-collection--numbered {
		@include oGridRespondTo(M) {
			.o-teaser-collection__item::after {
				content: '';
				position: absolute;
				top: 0;
				right: 8px;
				border-right: 1px solid $_o-teaser-collection-color-thin-border;
				height: calc(100% - 15px);
			}
			// Never show on last one
			.o-teaser-collection__item:last-of-type:after {
				display: none;
			}
		}
		// Assumes three per row displayed
		@include oGridRespondTo($from: M, $until: XL) {
			.o-teaser-collection__item:nth-of-type(3n):after {
				display: none;
			}
		}
	}
}

.o-teaser-collection__heading--centre {
	text-align: center;
}

.o-teaser--centered {
	padding-bottom: 5px; // additional space to ensure the small image is associated with the right story
	text-align: center;

	@include oGridRespondTo($from: M, $until: L) {
		border-bottom-width: 0; //sort of Next specific - teasers are lined up on M, so remove border
	}

	.o-teaser__image-container {
		display: block; // they should always keep their image
		padding: 0 15% !important; // and the image should be smaller than the hero centered
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:308");