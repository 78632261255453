@use "sass:meta" as ---0igrzsxbepg6;$o-ads-is-silent: false !default;
@import '@financial-times/ads-display/main';

$ad-medium-breakpoint: 760px;

// Improve ads slot collapse (Hide slot when slot is 1x1 except header)
// ------------------------------------------------

[data-o-ads-loaded="OneByOne"],
[data-o-ads-loaded="false"] {
	&:not(.above-header-advert) {
		display: none;
	}
}

.advert--small-top,
.advert--sidebar,
.advert--instream,
.advert--top-small-medium,
.advert--mid-small-medium,
.advert--lead,
.advert--medium-mid,
.advert--mid-responsive-large,
.advert--mid-leaderboard {
	&[data-o-ads-loaded] {
		margin-top: 15px;
		margin-bottom: 50px;

		@include oGridRespondTo(L) {
			margin: 0 0 35px;
		}
	}
}

.advert--mid-leaderboard {
	&[data-o-ads-loaded] {
		@include oGridRespondTo(L) {
			margin-left: -15px;
		}
	}
}

.advert--sidebar-mid+.advert--sidebar-mid1,
.industry-recommendations+.advert--sidebar-mid1,
.advert--extra-padding.advert--extra-padding {
	margin-top: 100vh;
}

.stream__right-hand--above .industry-recommendations+.advert--sidebar-mid1 {
	margin-top: 15px;
}

.advert--sidebar {
	margin-left: 16px;
}

.stream__right-hand {
	.advert--sidebar {
		&[data-o-ads-loaded] {
			@include oGridRespondTo($from: L, $until: XL) {
				margin-left: 20px;
			}
		}
	}
}

.above-header-advert {
	display: none;
	padding: 0;

	@media (min-width:$ad-medium-breakpoint) {
		display: block;
	}
}

.related-content-advert {
	width: 300px;
	height: 250px;
	margin: 0 auto;

	@include oGridRespondTo(S) {
		float: right;
	}

	@include oGridRespondTo(XL) {
		margin-top: 30px;
	}

	&[data-o-ads-loaded="false"] {
		display: none;
	}
}

// Hides / Unhides lower responsive only ad slot dependent upon responsive ad being loaded into masthead slot
.responsive-advert {
	visibility: hidden;
	height: 0;
	width: 0;
	overflow: hidden;

	&[data-o-ads-loaded="Responsive"][data-o-ads-master-loaded="Responsive"] {
		visibility: visible;
		height: auto;
		width: auto;
		clear: both;
	}
}

.advert--mid-instream {
	padding: 5px 0 10px;
	border-bottom: 1px #cec6b9 solid;

	&[data-o-ads-loaded="false"] {
		display: none;
	}
}

.advert--lead {
	&[data-o-ads-loaded="false"] {
		margin: 0;
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:150");