@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-typography/main';

.stream__pagination {
	margin: 16px 0 24px;

	// from o-buttons@v6.1.0, display:flex was added on o-buttons-pagination
	&.o-buttons-pagination {
		justify-content: center;
		align-items: center;
	}
}

.stream__pagination-label {
	@include oTypographySans(-1);
	margin: 0 0.5em;
}

.stream__index-count {
	margin: 0;
	padding-left: 20px;
	@include oGridRespondTo('M') {
		padding-left: 0;
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:307");