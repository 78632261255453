@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

.mini-brand__header {
	background-color: oColorsByName('oxford-30');

	.mini-brand__flex {
		width: 100%;
		padding-top: oSpacingByName('s4');
		padding-bottom: oSpacingByName('s4');
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;

		.mini-brand__flex-item {
			flex: 0 0 content;
		}

		.mini-brand__flex-space {
			flex: 1 1 auto;
		}
	}

	.mini-brand__page-title {
		@include oTypographyDisplay(6);
		@include oEditorialTypographyHeading($level: 1);
		color: oColorsByName('white');
		margin-top: oSpacingByName('s1');
		margin-right: oSpacingByName('s4');
		margin-bottom: oSpacingByName('s1');
		line-height: 1em;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
	}

	.n-myft-follow-button {
		margin-top: oSpacingByName('s1');
	}

	.mini-brand__sponsor {
		display: none;

		@include oGridRespondTo(L) {
			display: block;
		}
	}

	.mini-brand__sponsor-text {
		@include oTypographySans(0);
		color: oColorsByName('white');
		margin: oSpacingByName('s1') 0;
	}

	.mini-brand__sponsor-logo {
		width: auto;
		height: oSpacingByName('s6');
	}
}

.mini-brand__header-stripe {
	width: 100%;
	height: oSpacingByName('s3');
	background-color: oColorsByName('oxford-30');

	&.climate_capital {
		background-color: mix(oColorsByName('jade'), oColorsByName('sky'), 40%);
	}

	&.moral_money {
		background-color: oColorsByName('teal-80');
	}

	&.energy_source {
		// 'Graphics Dark Blue' color, to be replaced if added to Origami.
		background-image: linear-gradient(to right, #006F9B, oColorsByName('teal'), oColorsByName('jade'));
	}

	&.due_diligence {
		background-color: oColorsByName('lemon');
	}

	&.tech_asia {
		// 'Nikkei Blue' color, to be replaced if added to Origami.
		background-color: #0A3E6F;
		border-top: 1px solid oColorsByName('paper');
	}

	&.scoreboard {
		background-color: oColorsByName('lemon');
	}

	&.trade_secrets {
		// 'Graphics Dark Blue' color, to be replaced if added to Origami.
		background-image: linear-gradient(to right, #006F9B, oColorsByName('sky'));
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:147");