$system-code: 'next-stream-page';

$o-teaser-collection-is-silent: false;
@import '@financial-times/o-teaser-collection/main';
.o-teaser-collection--stream .o-teaser__content {
	padding-right: 65px;
}

.o-teaser-collection--stream .o-teaser__action {
	position: absolute;
	right: 0;
	top: 0;
}

@import '@financial-times/o-editorial-typography/main';
@include oEditorialTypography();

@import 'components/sub-header/main';
@import 'components/mini-brand/header';
@import 'components/ads/main';
@import 'components/globetrotter/main';
@import 'components/community-events/main';

// Being very specific with these selectors so they are not de-duped and removed
form.n-myft-ui.n-myft-ui--follow { // stylelint-disable-line
	display: inline-block;
}

@import '@financial-times/n-myft-ui/components/button/main';
@import '@financial-times/n-myft-ui/components/follow-button/main';

@import '@financial-times/o-grid/main';

[data-o-grid-colspan~="Moffset4"] {
	@include oGridRespondTo(M) {
		@include oGridOffset(4);
	}
}

[data-o-grid-colspan~="Moffset3"] {
	@include oGridRespondTo(M) {
		@include oGridOffset(3);
	}
}

[data-o-grid-colspan~="Loffset3"] {
	@include oGridRespondTo(L) {
		@include oGridOffset(3);
	}
}

[data-o-grid-colspan~="Loffset1"] {
	@include oGridRespondTo(L) {
		@include oGridOffset(1);
	}
}

[data-o-grid-colspan~="XLoffset1"] {
	@include oGridRespondTo(XL) {
		@include oGridOffset(1);
	}
}

@import 'components/sub-header/author';

$o-teaser-is-silent: false;
@import '@financial-times/o-teaser/main';
$o-labels-is-silent: false;
@import '@financial-times/o-labels/main';

// stream and section
@import 'components/section/main';

@import 'components/stream-item/main';

@import 'components/graphic-section/main';

// everywhere apart from hub
@import 'components/stream-card/main';
@import 'components/stream/main';

@import 'components/hub-page/main';

@import 'components/jobs-box/main';

// configure the parts of o-buttons we need
@import '@financial-times/o-buttons/main';
@include oButtons($opts: (
	types: (primary, secondary),
	sizes: (big),
	icons: ( arrow-left, arrow-right, arrow-up ),
	groups: true,
	pagination: true
));

@import '@financial-times/n-myft-ui/myft/main';
@import 'components/pagination/main';
@import 'components/teaser-collections/main';
$o-video-is-silent: false;
@import '@financial-times/o-video/main';
@import '@financial-times/n-myft-ui/components/collections/main';
@import '@financial-times/n-syndication/main';
@import '@financial-times/o-spacing/main';

@import '@financial-times/n-exponea/main';
@include nExponea();

@import '@financial-times/o-overlay/main';
@include oOverlay();
