@use "sass:meta" as ---0igrzsxbepg6;// HACK: to add horizontal rule below 1st story in books
[data-trackable="books"] div:first-of-type {
	.o-teaser {
		border-bottom: 1px solid #ccc1b3;
	}
}

// HACK: retain teaser images on default & small at a slighty increased ratio
.o-teaser--small {
	@include oGridRespondTo($until: M) {
		.o-teaser__image-container {
			display: block;
		}
	}
}

.o-teaser--stream-card {
	display: grid;
}

//HACK override ad placement on hub page.
.advert--sidebar {
	&[data-o-ads-loaded] {
		@include oGridRespondTo($from: L, $until: XL) {
			margin-left: 0;
		}
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:258");