@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

.sub-header__description {
	margin-bottom: 30px;

	@include oGridRespondTo(M) {
		margin-bottom: 10px;
	}
}

.sub-header__content--links {
	position: relative;
	margin-bottom: 10px;
	@include oTypographySans($scale: 0, $weight: 'semibold');

	.sub-header--author & {
		min-height: 30px;
	}

	@include oGridRespondTo(M) {
		a:not(:first-child) {
			margin-left: 50px;
		}
	}
}

.sub-header__content--links {
	display: block;
	margin-top: 30px;
}

.sub-header__content__link {
	display: block;
	max-width: calc(100% - #{$author-img-default-width});
	border: 0;
	color: oColorsByName('black-70');

	i {
		border-radius: 24px;
	}

	span {
		margin-left: -5px;
		vertical-align: top;
	}

	&:hover,
	&:focus {
		color: oColorsByName('black-70');

		i {
			opacity: 0.8;
		}
	}

	@include oGridRespondTo(M) {
		display: inline;
	}
}

.sub-header__content__link--email-address {
	i {
		background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/fticon-v1%3Amail?source=next&width=24&height=24);
		background-size: contain;
		display: inline-block;
		width: 24px;
		height: 24px;
		position: relative;
		left: - oSpacingByName('s1');
		top: -1px;
	}
}

.sub-header__content__link--twitter-handle {
	i {
		background-image: url(https://www.ft.com/__origami/service/image/v2/images/raw/ftsocial-v2%3Ax?source=next-stream-page&width=24&height=24);
		background-size: contain;
		display: inline-block;
		width: 24px;
		height: 24px;
		position: relative;
		left: - oSpacingByName('s1');
		top: -1px;
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:214");