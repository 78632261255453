@use "sass:meta" as ---0igrzsxbepg6;// css grid for section
.css-grid__container {
	display: grid;
	grid-template-columns: 12fr;
	grid-template-rows: auto;
	@include oGridRespondTo(L) {
		grid-template-columns: 8fr 0 4fr;
	}
	@include oGridRespondTo(XL) {
		grid-template-columns: 8fr 1fr 3fr;
	}
}

.css-grid__item-top {
	grid-column: 1;
	grid-row: 1;
}

.css-grid__item-top--without-sidebar {
	grid-column: 1/-1;
}

.css-grid__item-bottom--full-width {
	grid-column: 1/-1 !important;
	.o-teaser-collection__list {
		@include oGridRespondTo($from: L) {
			padding-right: 200px;
		}
	}
}

.css-grid__item-bottom {
	grid-column: 1;
	grid-row: 3;
	@include oGridRespondTo(L) {
		grid-row: 2;
	}
}

.css-grid__sidebar {
	grid-column: 1;
	grid-row: 2;
	@include oGridRespondTo(L) {
		grid-column: 3;
		grid-row: 1 / span 2;
		.o-ads--center {
			text-align: right;
		}
	}
	.advert--sidebar {
		margin-left: 0;
	}
}

.css-grid__sidebar-item {
	@include oGridRespondTo(L) {
		padding-left: 20px;
	}

	@include oGridRespondTo(XL) {
		padding-left: 0px;
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:248");