@use "sass:meta" as ---0igrzsxbepg6;.stream__right-hand {
	margin-top: 20px;
}

.stream__right-hand--has-title {
	margin-top: 32px;
}

.stream-spacer {
	width: 100%;
	height: 32px;
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:257");