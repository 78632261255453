@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-icons/main';
@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

@import '@financial-times/o-forms/main';

.jobs-box {
	@include oTypographySans(-2);
	border: 1px solid oColorsByName('black-20');
	position: relative;
	padding: 15px 25px 15px 20px;
	margin-top: 10px;
}

.jobs-box__search-form {
	padding: 15px 0;
	border-bottom: 1px solid oColorsByName('black-20');
}

.jobs-box__title {
	@include oTypographyDisplay(3);
	position: absolute;
	background-color: oColorsByName('paper');
	color: oColorsByName('black-70');
	display: block;
	margin: 0;
	padding: 0 10px;
	left: 10px;
	top: -17px;
}

.jobs-box__button {
	@include oButtonsContent($opts: ('themes': ('standout')));
	margin-left: 10px;
}

.jobs-box__text-field {
	@include oForms($opts: ('features': ('small')));
	padding: 0 5px;
	border-color: oColorsByName('teal-40');
	height: 26px;
	vertical-align: middle;
	font-family: inherit;
}

.jobs-box__job-link {
	color: oColorsByName('black-70');
	border-bottom: 0;
	margin: 10px 0;
	&:hover {
		color: oColorsByName('teal-40');
	}
}

.jobs-box__job-title {
	@include oTypographySans($scale: -2, $weight: 'semibold');
}

.jobs-box__newsletter-link {
	@include oTypographySans($scale: -2, $weight: 'semibold');
	color: oColorsByName('teal-40');
	border-bottom: 0;
	display: inline-block;
	margin-top: 15px;
	line-height: 26px;
	&:after {
		@include oIconsContent(
			$icon-name: 'arrow-right',
			$color: oColorsByName('teal-40'),
			$size: 12
		);
		content: '';
		margin-top: -2px;
		margin-left: 2px;
	}
	&:hover {
		color: oColorsByName('teal-40');
	}
	@include oGridRespondTo(L) {
		margin: 0;
	}
}

.jobs-box__job-listing {
	margin: 20px 0;
	@include oGridRespondTo(L) {
		margin: 15px 0 0;
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:259");