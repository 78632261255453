@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

.graphics {
	margin-bottom: 20px;
	padding-bottom: 18px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	display: grid;
	column-gap: 20px;
	row-gap: 18px;
	grid-template-rows: auto;
	grid-template-columns: 10fr;
	grid-template-areas: "header" "picture" "teaser";

	.o-teaser--small {
		border-bottom: 0;
	}

	.o-teaser__heading {
		@include oTypographySans($scale: 3, $include-font-family: false);
	}

	@include oGridRespondTo(L) {
		grid-template-columns: 7fr 3fr;
		grid-template-areas: "header header" "picture teaser";

		.o-teaser__heading {
			@include oTypographySans($scale: 5, $include-font-family: false);
		}
	}

	@include oGridRespondTo(XL) {
		grid-template-columns: 6fr 4fr;

		.o-teaser__heading {
			@include oTypographySans($scale: 6, $include-font-family: false);
		}
		.o-teaser__content {
			text-align: center;
		}
	}
}

.graphics__header {
	grid-area: header;

	.o-teaser-collection__heading {
		padding-bottom: 0;
	}
}

.graphics__picture {
	grid-area: picture;
}

.graphics__teaser {
	grid-area: teaser;
	align-self: center;
}

.graphics--tinted,
.graphics--inverse {
	position: relative;

	&:after {
		content: "";
		margin-left: -50vw;
		width: 100vw;
		height: 100%;
		right: auto;
		left: 50%;
		position: absolute;
		top: 0;
		z-index: -1;
	}
}

.graphics--tinted {
	&:after {
		background: oColorsByName('white-60');
	}
}

.graphics--inverse {
	&:after {
		background: oColorsByName('slate');
	}

	.o-teaser-collection__heading {
		color: oColorsByName('white');

		&:after {
			border-top-color: oColorsByName('claret');
		}
	}
}

.graphics--wide {
	@include oGridRespondTo(L) {
		grid-template-columns: 10fr;
		grid-template-areas: "header" "teaser" "picture";
	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:251");