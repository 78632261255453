@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

.stream-card__date {
	margin-bottom: oSpacingByName('s4');

	@include oGridRespondTo('L') {
		text-align: right;
	}

	&--hidden {
		display: none;
	}

	.o-date {
		color: oColorsByName('black-70');
		@include oTypographySans($scale: -1, $weight: 'semibold');

		&[data-o-date-format="today-or-yesterday-or-nothing"] {
			text-transform: capitalize;
		}

		&[data-o-date-format="date-only"] {
			text-transform: uppercase;
		}

	}
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:253");