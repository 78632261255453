@use "sass:meta" as ---0igrzsxbepg6;@keyframes highlight {
	0% {
		background: #f5dfd9;
	}
	100% {
	}
}

@keyframes load-in {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.stream-item--new-highlight {
	animation: load-in 0.6s forwards, highlight 3s 2s backwards;
}

.stream-item--new-animate {
	animation: load-in 0.6s forwards;
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:249");