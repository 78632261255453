@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-typography/main';

.gt-header {
	display: flex;
	flex-direction: column;

	margin-top: oSpacingByName('s3');
	padding: oSpacingByName('s4') oSpacingByName('s4') oSpacingByName('s6');

	color: #ffffff;
	background-color: #082a4d;

	@include oGridRespondTo(M) {
		flex-direction: row;

		padding: oSpacingByName('s4');
	}

	a {
		color: inherit;
		border-color: #ffffff;
		border-width: 1px;
	}
}

.gt-header__content {
	@include oGridRespondTo(XL) {
		padding-right: 200px;
	}
}

.gt-header__map {
	background-repeat: no-repeat;
	background-size: cover;

	@include oGridRespondTo(M) {
		background-image: url("https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fft-next-assets-prod.s3-eu-west-1.amazonaws.com%2Fassets%2Fglobetrotter-map.svg?source=next-stream-page&format=svg&quality=lossless");
		background-position: top -50px right -75px;
	}

	@include oGridRespondTo(XL) {
		background-image: url("https://www.ft.com/__origami/service/image/v2/images/raw/https%3A%2F%2Fft-next-assets-prod.s3-eu-west-1.amazonaws.com%2Fassets%2Fglobetrotter-map.svg?source=next-stream-page&format=svg&quality=lossless");
		background-position: top -110px right -75px;
	}
}

.gt-header__image {
	img {
		width: 100%;
	}

	@include oGridRespondTo(M) {
		flex: 0 0 40%;

		padding-right: oSpacingByName('s6');
	}

	@include oGridRespondTo(XL) {
		flex: 0 0 30%;

		padding-right: oSpacingByName('s6');
	}
}

.gt-header__subtitle {
	margin: oSpacingByName('s2') 0 oSpacingByName('s6');

	position: relative;

	@include oTypographySans($scale: 4, $weight: 'semibold');

	&:after {
		content: "";
		position: absolute;

		bottom: -#{oSpacingByName('s3')};
		left: 0;

		width: 35px;
		height: 4px;

		background-color: #ffffff;
	}
}

.gt-header__body {
	margin: 0 0 oSpacingByName('s3');
}

.gt-header__sponsor {
	margin: oSpacingByName('s3') 0 0;

	@include oTypographySans(-1);

	@include oGridRespondTo(M) {
		margin: 0;
	}
}

.gt-header__text {
	@include oTypographySans(0);
}

.gt-sponsor {
	margin-top: oSpacingByName('s2');
	margin-bottom: oSpacingByName('s3');
	margin-left: 10px;

	@include oGridRespondTo(M) {
		margin-left: 0;
	}
}

.gt-sponsor__logo {
	margin-left: oSpacingByName('s1');
	width: 87px;
	height: 16px;

	@include oGridRespondTo(M) {
		width: 100px;
		height: 18px;
	}
}

.gt-spritesheet {
	display: none;
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:153");