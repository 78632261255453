@use "sass:meta" as ---0igrzsxbepg6;@import '@financial-times/o-colors/main';
@import '@financial-times/o-typography/main';

$author-img-default-width: 100px;

.sub-header {
	padding: 15px 0;
	margin-bottom: oSpacingByName('s6');

	&.sub-header--brand {
		background-color: oColorsMix($color: 'wheat', $percentage: 40);
	}

	&.sub-header--author {
		background-color: oColorsByName('white-60');
	}

	&.sub-header--company {
		background-color: oColorsMix($color: 'wheat', $percentage: 40);
	}

	&.sub-header--minibrand {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.sub-header--standout {
		background-color: oColorsByName('wheat');
	}

	.n-myft-ui--follow {
		vertical-align: top;
	}
}

.sub-header__section {
	position: static;
	padding-left: 20px;

	@include oGridRespondTo(M) {
		padding: 0 10px 0 16px;
	}

	.n-myft-follow-button {
		margin-top: 0;
		@include oGridRespondTo(L) {
			margin-top: 6px;
		}
	}
}

.sub-header__description {
	@include oTypographySans(1);
}

.sub-header--alphaville {
	background-color: oColorsByName('matisse');
	color: oColorsByName('white');
	min-height: 174px;
	position: relative;
	padding: 0;
	.sub-header__page-title,
	.sub-header__strapline {
		color: inherit;
	}
	.sub-header__description {
		p {
			margin: 0;
			color: mix(oColorsByName('white') , oColorsByName('matisse'), 80);
		}
	}
	.sub-header__section {
		margin-top: 15px;
	}
	.sub-header__image--alphaville {
		display: block;
	}

	p > a {
		color: mix(oColorsByName('white'), oColorsByName('matisse'), 80);
		&:hover,
		&:focus {
			color: oColorsByName('white');
			text-decoration-color: oColorsByName('white');
		}
	}
	:focus-visible {
		outline: 2px solid oColorsByName('white');
	}
}
.sub-header__image-wrapper--alphaville {
	@include oGridRespondTo(M) {
		padding: 0;
	}

}

.sub-header--special-reports {
	background-color: oColorsByName('claret');
	color: oColorsByName('white');
	.sub-header__page-title {
		@include oTypographySans(6);
		color: inherit;
		-webkit-font-smoothing: antialiased;
	}
	.sub-header__description {
		color: mix(oColorsByName('white'), oColorsByName('claret'), 80%);
	}
	p > a {
		color: oColorsByName('white');
		opacity: 0.8;
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

.sub-header--author {
	.sub-header__section--has-img {
		min-height: 170px;
		.sub-header__strapline {
			max-width: calc(100% - #{$author-img-default-width});

			@include oGridRespondTo(M) {
				max-width: 100%;
			}
		}
	}
}

.sub-header__page-title {
	@include oTypographyDisplay(6);
	@include oEditorialTypographyHeading($level: 1);
	margin-top: oSpacingByName('s1');
	margin-bottom: oSpacingByName('s1');
	line-height: 1em;
	display: inline-block;
	margin: 0 10px 0 0;
}

.sub-header__image-wrapper--brand {
	padding: 10px 0 0 10px;
	float: right;

	@include oGridRespondTo(M) {
		position: absolute;
		height: 100%;
		top: 0;
		right: 16px;
		padding: 0;
	}
}

.sub-header__image-wrapper--author {
	position: absolute;
	height: 100%;
	top: 0;
	right: 16px;

	@include oGridRespondTo(XL) {
		padding-right: 60px;
	}
}

.sub-header__image--brand {
	height: 100px;
	width: 100px;
	border-radius: 100%;
	filter: sepia() grayscale(50%);

	@include oGridRespondTo(M) {
		height: 125px;
		width: 125px;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

.sub-header__image--author {
	height: $author-img-default-width;
	width: $author-img-default-width;
	position: relative;
	top: calc(100% + 15px);
	transform: translateY(-100%);

	@include oGridRespondTo(M) {
		height: 200px;
		width: 200px;
	}
}

.sub-header__tools {
	@include oGridRespondTo(M) {
		display: none;
	}
}

.sub-header__strapline {
	@include oTypographySans($scale: 0, $weight: 'semibold');
	color: oColorsByName('black-70');
	text-transform: uppercase;
	line-height: 24px;
	min-height: 24px;
	margin: 10px 0;
}

.sub-header__page-title-kicker {
	display: block;
	text-transform: capitalize;
}

.sub-header__page-title-kicker--link {
	@include oEditorialTypographyTag('topic');
	@include oTypographySans($scale: -1, $include-font-family: false);
}

.sub-header__content__link {
	text-decoration: none;
}

;@include ---0igrzsxbepg6.load-css("sass-embedded-legacy-load-done:146");